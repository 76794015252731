// Eshop status map
export const eshopStatusLabels = {
    "-2": "Eliminato",
    "-1": "Attesa optin",
    0: "Disabilitato",
    1: "Attivo",
    2: "Ibrido",
};

// Eshop status colors
export const eshopStatusColors = {
    "-2": "danger",
    "-1": "info",
    0: "warning",
    1: "success",
    2: "secondary",
};

// Eshop Billing status colors
export const eshopBillingStatusLabels = {
    "-1": "Moroso",
    0: "Disdetto",
    1: "Regolare",
};

// Eshop Billing status colors
export const eshopBillingStatusColors = {
    "moroso": "danger",
    "disdetto": "warning",
    "regolare": "success",
};

// Review status map
export const statusMapping = {
    "suspend": 5,
    "valid_certificate": 1,
    "valid": 1,
    "not_valid": 2,
};

// Review status labels
export const statusLabels = {
    0: "In attesa di validazione",
    1: "Validata",
    2: "Non validata",
    3: "Attesa pubblicazione",
    4: "Eliminata da utente",
    5: "Sospesa",
};

// Review status colors
export const statusColors = {
    0: "warning",
    1: "success",
    2: "danger",
    3: "primary",
    4: "danger",
    5: "secondary",
};

export const statusReportLabels = {
    0: "Da processare",
    1: "Recensione valida",
    2: "Recensione fake",
    3: "In attesa prova",
};

// Pagination page size
export const manualPageSize = [25, 50, 100, 200];

export function formatDate(dateString) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    const date = new Date(dateString);
    return date.toLocaleDateString('it-IT', options);
}

export function checkReviewMedia(item) {
    let review_medias_obj = undefined;
    if ((item.images !== null && item.images !== "") || (item.videos !== null && item.videos !== "")) {
        review_medias_obj = {};
        review_medias_obj["id"] = item.id;
        review_medias_obj["has_data"] = false;
        review_medias_obj["isProduct"] = item.id_prod ? true : false;
        try {
            if (item.images !== undefined) {
                const images = JSON.parse(item.images);
                if (Array.isArray(images) && images.length > 0) {
                    review_medias_obj["images"] = images;
                    review_medias_obj["has_data"] = true;
                }
            }
        } catch (error) {
            console.error('Error parsing images:', item.images);
        }
        try {
            if (item.video_url !== undefined) {
                const videos = JSON.parse(item.video_url);
                if (videos !== null) {
                    review_medias_obj["videos"] = [videos];
                    review_medias_obj["has_data"] = true;
                }
            }
        } catch (error) {
            console.error('Error parsing video_url:', item.video_url);
        }
    }
    return review_medias_obj
}

// Eshop status map
export const eshopMothlyOrdersLabels = {
    0: "-",
    1: "Ancora nessuna vendita",
    2: "Qualche vendita sporadica",
    3: "10 - 20 vendite al mese",
    4: "20 - 100 vendite al mese",
    5: "100 - 1000 vendite al mese",
    6: "1000 - 1500 vendite al mese",
    7: "Più di 1500 vendite al mese"
};

// Eshop lang ref map
export const eshopLangRefLabels = {
    "": "-",
    "IT": "Italiano",
    "EN": "Inglese"
};

// PaymentMethods mock
export const paymentMethods = {
    "1": "Bonifico",
    "2": "Carta di credito",
    "3": "Paypal",
    "4": "Contrassegno",
    "5": "Altro"
};

export const plansMapLabels = {
    "lite": "Lite",
    "pro": "Pro",
    "company": "Company",
};

export const plansStatusLabels = {
    "0": "Attesa pagamento",
    "1": "Attivo",
    "2": "Scaduto",
    "3": "attesa attivazione",
};

export const plansStatusColors = {
    "0": "warning",
    "1": "success",
    "2": "danger",
    "3": "info",
};

export const plansPaymentIntervalLabel = {
    "mensile": "Mensile",
    "annuale": "Annuale",
    "semestrale": "Semestrale",
};

export const bonusInvitationThreshold = {
    "0": "-",
    "100": "100 Inviti",
    "2000": "2000 Inviti",
    "2500": "2500 Inviti",
};

// Coupon status map
export const couponStatusLabels = {
    "-2": "Eliminato da utente",
    0: "Da validare",
    1: "Validato",
    2: "Rifiutato",
};

// Coupon status colors
export const couponStatusColors = {
    "-2": "secondary",
    0: "warning",
    1: "success",
    2: "danger",
};

// Coupon discount type map
export const couponDiscountTypeMap = {
    0: "€",
    1: "%",
};

// import status map
export const eshopImportStatusLabels = {
    "-1": "Eliminato",
    0: "Non caricato",
    1: "Validato",
    2: "In attesa",
    4: "In lavorazione",
};

// import status colors
export const eshopImportStatusColors = {
    "-1": "danger",
    0: "danger",
    1: "success",
    2: "warning",
    4: "info",
};

// invitation status map
export const eshopInvitationStatusLabels = {
    0: "In lavorazione",
    1: "In spedizione",
    2: "Email spedite",
};

// invitation status colors
export const eshopInvitationStatusColors = {
    0: "info",
    1: "warning",
    2: "success",
};

// Order status map
export const orderSendLabels = {
    0: "Email",
    1: "Sms",
    2: "Email + Sms",
    3: "Email + Whatsapp",
    4: "Whatsapp",
    5: "Email + Sms + Whatsapp",
};

// Order status colors
export const orderStatusLabel = {
    0: "Non utilizzato",
    1: "Utilizzato",
};

// Order status colors
export const orderStatusColors = {
    0: "danger",
    1: "success",
};

// Order send colors
export const orderSendStatusLabel = {
    "-2": "Reminder disattivato",
    "-1": "Email disattivate",
    0: "Da inviare",
    1: "Inviato",
    4: "Dominio bloccato",
    5: "Da inviare",
    6: "Ordine ripetuto",
};

// Order send colors
export const orderSendStatusColors = {
    "-1": "secondary",
    0: "warning",
    1: "success",
};

// Import product status colors
export const importProductStatusLabel = {
    "-1": "Errore",
    0: "In lavorazione",
    1: "Attivo",
};

// Import product status colors
export const importProductStatusColors = {
    "-1": "danger",
    0: "warning",
    1: "success",
};

// Renew product status colors
export const renewProductStatusLabel = {
    "-1": "Nessun rinnovo",
    0: "Manuale",
    1: "Ricorsivo",
};

// Renew product status colors
export const renewProductStatusColors = {
    "-1": "danger",
    0: "warning",
    1: "success",
};

// invitation status colors
export const defaultStatusColors = {
    0: "danger",
    1: "success",
    2: "warning",
};

export const imageNotFound = process.env.REACT_APP_ESA_CDN_URL + "utility/image_not_found.jpg";