import React, { useState, useEffect, useRef } from 'react';
import { Button, Badge } from 'react-bootstrap';
import { FaKeyboard } from "react-icons/fa";

export const PartialProductReviewAction = ({ review, onApprove, indexValue, isActive }) => {
    const buttonRef = useRef();
    const [buttonClicked, setButtonClicked] = useState(false);

    useEffect(() => {
        const button = buttonRef.current;

        const handleKeyPress = (event) => {
            if (!isActive) return; // Ensure the button is only active on the visible carousel slide

            const key = parseInt(event.key, 10); // Convert key to a number
            if (key === indexValue) {
                button?.click(); // Simulate a button click for the specific keypress
            }
        };

        if (isActive && button) {
            // Attach listener only if this button is part of the active slide
            window.addEventListener('keydown', handleKeyPress);
        }

        return () => {
            // Clean up the listener
            if (button) {
                window.removeEventListener('keydown', handleKeyPress);
            }
        };
    }, [isActive, indexValue, review]);

    if (!review) {
        return (
            <Badge bg="danger">
                Recensione mancante
            </Badge>
        );
    }

    const handleButtonClick = (type) => {
        onApprove(type);
        setButtonClicked(true);
    };


    return (
        <div className="d-flex justify-content-end">
            {!buttonClicked && review.processed == 1 && (
                <Badge bg="success" className="me-2" disabled >
                    Processed
                </Badge>
            )
            }
            {!buttonClicked && review.rating && !review.description && review.processed != 1 && (
                <Button
                    ref={buttonRef} // Attach ref to the button
                    variant="warning"
                    size="sm"
                    onClick={() => handleButtonClick('only_rating')}
                    tabIndex={indexValue} // Set tabindex for navigation
                >
                    Approva con solo rating<br /><FaKeyboard className="ms-1" /> ({indexValue})
                </Button>
            )}
            {!buttonClicked && review.rating && review.description && review.processed != 1 && (
                <Button
                    ref={buttonRef} // Attach ref to the button
                    variant="success"
                    size="sm"
                    onClick={() => handleButtonClick('all')}
                    tabIndex={indexValue} // Set tabindex for navigation
                >
                    Approva<br /><FaKeyboard className="ms-1" />  ({indexValue})
                </Button>
            )}
            {buttonClicked && (
                <Button
                    variant="primary"
                    size="sm"
                    disabled
                >
                    In Coda
                </Button>
            )}
        </div>
    );
};