// list.js
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartialReviews, setPartialReviewStatus } from './actions/actions';
import PartialReviewCarousel from './components/PartialReviewCarousel';
import { customSuccessNotify, warningNotify } from '../App/Router';

const PartialReviewsList = () => {
    const dispatch = useDispatch();
    const { items, totalItems, currentPage, loading } = useSelector(state => state.partialReviews.partialReviews);

    useEffect(() => {
        dispatch(fetchPartialReviews(1));
    }, [dispatch]);

    const handleReviewAction = (reviewId, actionType, data) => {
        //dispatch(fetchPartialReviews(currentPage + 1));
        customSuccessNotify("Recensione in coda per l'inserimento");
        console.log("Action:", reviewId, actionType, data);
        dispatch(setPartialReviewStatus({ reviewId, actionType, data }));
        
    };

    const handleLoadMore = useCallback(() => {
        dispatch(fetchPartialReviews(currentPage + 1));
    }, [dispatch, currentPage]);
    return (
        <div>
            <h3 className="text-center">Recensioni parziali</h3>
            <PartialReviewCarousel
                reviews={items}
                totalItems={totalItems}
                onAction={handleReviewAction}
                onLoadMore={handleLoadMore}
                totalItems={totalItems}
                loading={loading}
                currentPage={currentPage}
            />
        </div>
    );
};

export default PartialReviewsList;