import React from 'react';
import { ProgressBar } from 'react-bootstrap';

export const PartialReviewProgress = ({ steps = {} }) => {
    const stepOrder = ['slide_0', 'slide_1', 'slide_main_1'];
    const stepLabels = ['1 Conferma', '2 Conferma', 'Conferma finale'];

    if (steps === null || steps === undefined) {
        return null;
    }

    const getStepStatus = (step, index) => {
        if (!steps[step]) return 'pending';
        return 'completed';
    };

    const getElapsedTime = (currentStep, nextStep) => {
        if (!steps[currentStep] || !steps[nextStep]) return null;

        const start = new Date(steps[currentStep]);
        const end = new Date(steps[nextStep]);
        const diffSeconds = Math.round((end - start) / 1000);

        if (diffSeconds <= 0) return null;

        if (diffSeconds < 60) {
            return `${diffSeconds}s`;
        } else if (diffSeconds < 3600) {
            return `${Math.round(diffSeconds / 60)}m`;
        } else {
            return `${Math.round(diffSeconds / 3600)}h`;
        }
    };

    const completedSteps = Object.keys(steps).length;
    const progress = ((completedSteps - 1) / (stepOrder.length - 1)) * 100;

    return (
        <div className="container-fluid px-2 px-sm-4">
            <div className="position-relative py-5">
                {/* Main progress container */}
                <div className="d-flex justify-content-between align-items-center position-relative">
                    {/* Elapsed Time Labels */}
                    {stepOrder.slice(0, -1).map((step, index) => {
                        const status = getStepStatus(step, index);
                        const nextStep = stepOrder[index + 1];
                        const elapsedTime = getElapsedTime(step, nextStep);

                        if (elapsedTime) {
                            return (
                                <div
                                    key={`elapsed-${step}`}
                                    className={`position-absolute d-none d-sm-flex flex-column align-items-center ${status === 'completed' ? 'text-success' : 'text-secondary'
                                        }`}
                                    style={{
                                        left: `${(index + 1) * (99 / 3)}%`,
                                        top: '-1rem',
                                        transform: `translateX(-${80 / (index + 1)}%)`,
                                        width: 'auto',
                                        minWidth: '80px',
                                        zIndex: 2
                                    }}
                                >
                                    <small className="text-muted mb-1" style={{ fontSize: '0.75em' }}>
                                        tempo trascorso
                                    </small>
                                    <span className="fw-medium" style={{ fontSize: '0.875em' }}>
                                        {elapsedTime}
                                    </span>
                                </div>
                            );
                        }
                        return null;
                    })}

                    {/* Steps */}
                    {stepOrder.map((step, index) => {
                        const status = getStepStatus(step, index);
                        return (
                            <div
                                key={step}
                                className="position-relative"
                                style={{
                                    width: 'auto',
                                    minWidth: '100px',
                                    maxWidth: '150px',
                                    zIndex: 1
                                }}
                            >
                                <div
                                    className={`
                                        rounded d-flex align-items-center justify-content-center 
                                        ${status === 'completed' ? 'bg-success' : 'bg-secondary'}
                                        px-2 px-sm-3
                                    `}
                                    style={{
                                        minHeight: '48px',
                                        color: 'white',
                                        fontSize: 'clamp(0.75rem, 2vw, 1rem)',
                                        fontWeight: '500',
                                        textAlign: 'center',
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    {stepLabels[index]}
                                </div>
                            </div>
                        );
                    })}

                    {/* Progress Bar */}
                    <div
                        className="position-absolute bg-secondary w-100"
                        style={{
                            height: '6px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 0
                        }}
                    />
                    <div
                        className="position-absolute bg-success"
                        style={{
                            height: '6px',
                            width: `${progress}%`,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 0,
                            transition: 'width 0.5s ease-in-out'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};